import React, { useState } from 'react';

import PutForm from '../../../api/internal/PutForm';
import BillingTemplate from '../../templates/VendorBilling';
import Button from '../../../assets/essentials/Button';
import Form from '../../../assets/essentials/Form';

function Billing({
  data,
  vendorId,
  RefreshPage,
  HandlePageChange,
}: {
  data: VendorBilling;
  vendorId: Number;
  RefreshPage: Function;
  HandlePageChange: Function;
}) {
  //state being held where the form is submitted, child component manipulates the array
  const [billingEmails, setBillingEmails] = useState<Array<string>>(data.billingEmail);

  function UpdateBilling(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      vendorId: vendorId,
      //stores list of emails in db as a single string, backend parses string into array in get call
      billingEmail: billingEmails.join('; '),
    };
    
    PutForm(`/Vendor/UpdateVendorBilling`, formData, appendix).then(() => {
      RefreshPage('Billing', ['Audit', 'Summary']);
    });
  }

  return (
    <Form onSubmit={e => UpdateBilling(e)}>
      <BillingTemplate
        data={data}
        emails={billingEmails}
        HandlePageChange={HandlePageChange}
        setEmails={setBillingEmails}
        submitButtonId={vendorId + 'Billing'}
      />
      <div>
        <Button
          type="submit"
          variant="good"
          disabled={true}
          id={vendorId + 'Billing'}
        >
          Save Changes
        </Button>
      </div>
    </Form>
  );
}
export default Billing;

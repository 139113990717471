import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import View from '../../helpers/slab/View';
import ViewDriver from '../../features/views/users/driver/Driver';
import ViewTruck from '../../features/views/assets/truck/Truck';
import FilterData from '../../helpers/filter/FilterData';
import BBITable from '../../helpers/bBITable/BBITable';
import Get from '../../api/internal/Get';
import classes from '../../assets/Global.module.css';
import Header from '../../layouts/Header';
import { MdOutlineRefresh } from 'react-icons/md';
import { LoadingSpinner } from '../../helpers/loadingSpinner/LoadingSpinner';

function DriverHours() {
  const [driverHoursBoard, setDriverHoursBoard] = useState<DriverBoard[]>([]);
  const [lastUpdated, setLastUpdated] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const filterValue = useOutletContext();

  useEffect(() => {
    GetDriverHoursBoard();
  }, []);

  function GetDriverHoursBoard() {
    setIsLoading(true);
    Get(`/Employee/GetDriverHoursBoard`)
      .then(response => {
        if (response) {
          setDriverHoursBoard(response.data);
          setLastUpdated(new Date().toLocaleString());
        }
      })
      .finally(() => setIsLoading(false));
  }

  const handleRefreshClick = () => {
    GetDriverHoursBoard();
  };

  const columnConfig = [
    {
      key: '1',
      attribute: 'name',
      attributeprimaryhidden: 'employeeId',
      header: 'Driver',
      onClick: e => {
        if (e?.target?.dataset?.attributeprimaryhidden > 0) {
          View(
            <ViewDriver
              driverName={e.target.innerText}
              employeeId={e.target.dataset.attributeprimaryhidden}
            />,
          );
        }
      },
    },
    {
      key: '2',
      attribute: 'currentVehicleName',
      attributeprimaryhidden: 'assetId',
      header: 'Truck',
      onClick: e => {
        if (e?.target?.dataset?.attributeprimaryhidden > 0) {
          View(
            <ViewTruck
              truckName={e.target.innerText}
              assetId={e.target.dataset.attributeprimaryhidden}
            />,
          );
        }
      },
    },
    {
      key: '3',
      attribute: 'currentDutyStatus',
      header: 'Status',
    },
    {
      key: '4',
      attribute: 'timeInStatus',
      header: 'Time in Status',
    },
    {
      key: '5',
      attribute: 'timeUntilBreak',
      header: 'Time Until Break',
    },
    {
      key: '6',
      attribute: 'driveRemaining',
      header: 'Drive Time Remaining',
    },
    {
      key: '7',
      attribute: 'shiftRemaining',
      header: 'Shift Time Remaining',
    },
    {
      key: '8',
      attribute: 'cycleStartedAt',
      header: 'Cycle Started',
    },
    {
      key: '9',
      attribute: 'cycleRemaining',
      header: 'Cycle Remaining',
    },
    {
      key: '10',
      attribute: 'cycleTomorrow',
      header: 'Cycle Tomorrow',
    },
    {
      key: '11',
      attribute: 'cycleViolation',
      header: 'Cycle Violation',
    },
    {
      key: '12',
      attribute: 'shiftDrivingViolation',
      header: 'Shift Violation',
    },
    {
      key: '13',
      attribute: 'branch',
      header: 'Branch',
    },
  ];

  const tableData = FilterData(driverHoursBoard, filterValue);

  return (
    <>
      <Header className={classes.flexAlignCenterJustifyLeft}>
        {isLoading ? (
          <h3> Retrieving Hours.. </h3>
        ) : (
          <h3>Last Updated - {lastUpdated}</h3>
        )}
        <button onClick={handleRefreshClick}>
          <MdOutlineRefresh />
          <span>Refresh Board</span>
        </button>
      </Header>
      {isLoading ? (
        <div className={classes.spinnerContainer}>
          <LoadingSpinner />
        </div>
      ) : (
        <BBITable data={tableData} columns={columnConfig} />
      )}
    </>
  );
}

export default DriverHours;

import React, { useState, useEffect, useRef } from 'react';
import BBITable from '../../../helpers/bBITable/BBITable';
import classesCustom from '../../../helpers/search/CustomSearch.module.css';
import classesFinancialReport from './FinancialReport.module.css';
import FilterData from '../../../helpers/filter/FilterData';
import Get from '../../../api/internal/Get';
import Header from '../../../layouts/Header';
import View from '../../../helpers/slab/View';
import ViewLoad from '../../../features/views/load/Load';
import { MdDownload } from 'react-icons/md';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import PostBody from '../../../api/internal/PostBody';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import Input from '../../../assets/essentials/Input';
import Button from '../../../assets/essentials/Button';
import {
  ParseUrlForSearchParams,
  GenerateQueryString,
  GetLastFullWeekMonday,
} from '../../../../src/helpers/search/HelperFunctions';
import { useOutletContext } from 'react-router-dom';

function LoadFinancials() {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [loadFinancialsBoard, setLoadFinancialsBoard] = useState<
    Array<LoadFinancialsBoard>
  >([]);
  const emptySearchParams = {
    loadId: null,
    reference: null,
    branchId: null,
    customerId: null,
    employeeId: null,
    dateMin: null, //must use name "dateMin" if we want to use defaultSearchDate
    dateMax: null,
  };
  const [loadFinancialReportSearchParams, setLoadFinancialReportSearchParams] =
    useState(emptySearchParams);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const isFirstRender = useRef(true);
  const filterValue = useOutletContext();

  const defaultSearchDate = GetLastFullWeekMonday();

  useEffect(() => {
    setIsLoading(true);
    if (customerOptions.length === 0) {
      GetCustomerOptions();
    }
    if (driverOptions.length === 0) {
      GetDriverOptions();
    }
    if (branchOptions.length === 0) {
      GetBranchList();
    }

    const {
      loadId,
      reference,
      branchId,
      customerId,
      employeeId,
      dateMin,
      dateMax,
    } = ParseUrlForSearchParams(
      emptySearchParams,
      defaultSearchDate,
      isFirstRender.current,
    );

    setLoadFinancialReportSearchParams({
      loadId: loadId,
      reference: reference,
      branchId: branchId,
      customerId: customerId,
      employeeId: employeeId,
      dateMin: dateMin,
      dateMax: dateMax,
    });
    SearchLoadFinancialReport({
      loadId: loadId,
      reference: reference,
      branchId: branchId,
      customerId: customerId,
      employeeId: employeeId,
      dateMin: dateMin,
      dateMax: dateMax,
    });
    isFirstRender.current = false;
  }, []);

  function SearchLoadFinancialReport(SearchParamsFromForm?: Object) {
    const searchParams = SearchParamsFromForm
      ? SearchParamsFromForm
      : loadFinancialReportSearchParams;
    const queryString = '?' + GenerateQueryString(searchParams);
    const newUrl =
      window.location.origin + window.location.pathname + queryString;
    window.history.replaceState(null, null, newUrl);
    Get(`/Accounting/SearchLoadFinancialReport${queryString}`).then(
      response => {
        if (response) {
          setLoadFinancialsBoard(response.data);
          setIsLoading(false);
        }
      },
    );
  }

  function GetCustomerOptions() {
    Get(`/Customer/GetCustomerLoadReportOptions/`).then(response => {
      if (response) {
        setCustomerOptions(response?.data);
      }
    });
  }
  function GetDriverOptions() {
    Get(`/Employee/GetDriverOptions/`).then(response => {
      if (response) {
        setDriverOptions(response?.data);
      }
    });
  }
  function GetBranchList() {
    Get(`/Load/GetBranchList`).then(response => {
      if (response) {
        setBranchOptions(response.data);
      }
    });
  }

  function ClearSearchParams() {
    setLoadFinancialReportSearchParams(emptySearchParams);
  }

  function HandleFormSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    const formData = {
      loadId: e.target.loadId?.value,
      reference: e.target.reference?.value,
      branchId: e.target.branchId?.value,
      customerId: e.target.customerId?.value,
      employeeId: e.target.employeeId?.value,
      dateMin: e.target.dateMin?.value,
      dateMax: e.target.dateMax?.value,
    };
    setLoadFinancialReportSearchParams(formData);
    SearchLoadFinancialReport(formData);
  }

  function DownloadReportCSV(loads: LoadFinancialsBoard[]) {
    const loadIds = loads?.map(x => x.loadId);
    if (loadIds) {
      setIsLoading(true);
      PostBody('/Accounting/DownloadLoadFinancialReportCSV', {
        loadIds: [...loadIds],
      }).then(response => {
        if (response) {
          const blob = new Blob([response.data], { type: 'text/csv' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download =
            new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
            '_Load Financials Report.csv';
          a.click();
          URL.revokeObjectURL(url);
          setIsLoading(false);
        }
      });
    }
  }

  const formattedLoadFinancialsBoard = loadFinancialsBoard.map(
    loadFinancial => ({
      ...loadFinancial,
      pickupDate: DateOnlyFormatter(loadFinancial.pickupDate),
      dropOffDate: DateOnlyFormatter(loadFinancial.dropOffDate),
      miles: loadFinancial.miles.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      }),
      invoiceDate: DateOnlyFormatter(loadFinancial.invoiceDate),
      revenue: loadFinancial.revenue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      estFuel: loadFinancial.estFuel.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      payroll: loadFinancial.payroll.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      payable: loadFinancial.payable.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      margin: loadFinancial.margin.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      marginPercentage: (loadFinancial.marginPercentage * 100).toFixed(1),
    }),
  );

  const columnConfig = [
    {
      key: '1',
      attribute: 'loadId',
      header: 'Load #',
      onClick: e => View(<ViewLoad loadId={e.target.innerText} />),
      dataType: 'number',
    },
    {
      key: '2',
      attribute: 'equipment',
      header: 'Equipment',
    },
    {
      key: '3',
      attribute: 'loadStatus',
      header: 'Load Status',
    },
    {
      key: '4',
      attribute: 'reference',
      header: 'Reference',
    },
    {
      key: '5',
      attribute: 'customerNames',
      header: 'Customer',
    },
    {
      key: '6',
      attribute: 'branchName',
      header: 'Branch',
    },
    {
      key: '7',
      attribute: 'pickupDate',
      header: 'Pickup Date',
      dataType: 'date',
    },
    {
      key: '8',
      attribute: 'pickupLocationName',
      header: 'Pickup Location',
      dataType: 'string',
    },
    {
      key: '9',
      attribute: 'pickupAddress',
      header: 'Pickup Address',
      dataType: 'string',
    },
    {
      key: '10',
      attribute: 'dropOffDate',
      header: 'Drop Off Date',
      dataType: 'date',
    },
    {
      key: '11',
      attribute: 'dropOffLocationName',
      header: 'Drop Off Location',
      dataType: 'string',
    },
    {
      key: '12',
      attribute: 'dropOffAddress',
      header: 'Drop Off Address',
      dataType: 'string',
    },
    {
      key: '13',
      attribute: 'miles',
      header: 'Miles',
      dataType: 'formattedNumber',
      includeInTotal: true,
    },
    {
      key: '14',
      attribute: 'vendorNames',
      header: 'Vendor',
    },
    {
      key: '15',
      attribute: 'driverNames',
      header: 'Driver',
    },
    {
      key: '16',
      attribute: 'payrollStatus',
      header: 'Payroll Status',
    },
    {
      key: '17',
      attribute: 'invoiceDate',
      header: 'Invoice Date',
      dataType: 'date',
    },
    {
      key: '18',
      attribute: 'revenue',
      header: 'Revenue',
      dataType: 'currency',
      includeInTotal: true,
    },
    {
      key: '19',
      attribute: 'estFuel',
      header: 'Est. Fuel',
      dataType: 'currency',
      includeInTotal: true,
    },
    {
      key: '20',
      attribute: 'payroll',
      header: 'Payroll',
      dataType: 'currency',
      includeInTotal: true,
    },
    {
      key: '21',
      attribute: 'payable',
      header: 'Payable',
      dataType: 'currency',
      includeInTotal: true,
    },
    {
      key: '22',
      attribute: 'margin',
      header: 'Margin',
      dataType: 'currency',
      includeInTotal: true,
    },
    {
      key: '23',
      attribute: 'marginPercentage',
      header: 'Margin %',
      dataType: 'number',
      includeInTotal: true,
    },
  ];

  let tableData = FilterData(formattedLoadFinancialsBoard, filterValue);

  const SearchForm = () => {
    return (
      <div className={classesCustom.customSearch}>
        <form onSubmit={e => HandleFormSubmit(e)}>
          <label>
            Load #
            <Input
              type="text"
              name="loadId"
              defaultValue={loadFinancialReportSearchParams.loadId}
            />
          </label>
          <label>
            Reference #
            <Input
              type="text"
              name="reference"
              defaultValue={loadFinancialReportSearchParams.reference}
            />
          </label>
          <label>
            Branch
            <Select
              defaultValue={loadFinancialReportSearchParams.branchId || ''}
              name="branchId"
            >
              <option value="">All Branches</option>
              <OptionList
                optionData={branchOptions}
                attributeID="branchId"
                attributeName="branchName"
              />
            </Select>
          </label>
          <label>
            Customer
            <Select
              defaultValue={loadFinancialReportSearchParams.customerId || ''}
              name="customerId"
            >
              <option value="">All Customers</option>
              <OptionList
                optionData={customerOptions}
                attributeID="customerId"
                attributeName="customerName"
              />
            </Select>
          </label>
          <label>
            Driver
            <Select
              defaultValue={loadFinancialReportSearchParams.employeeId || ''}
              name="employeeId"
            >
              <option value="">All Drivers</option>
              <OptionList
                optionData={driverOptions}
                attributeID="employeeId"
                attributeName="name"
              />
            </Select>
          </label>
          <label>
            Pickup Date Start
            <Input
              type="date"
              name="dateMin"
              defaultValue={loadFinancialReportSearchParams.dateMin}
            />
          </label>
          <label>
            Pickup Date End
            <Input
              type="date"
              name="dateMax"
              defaultValue={loadFinancialReportSearchParams.dateMax}
            />
          </label>
          <div className={classesCustom.submitHolder}>
            <Button type="submit" variant="good">
              Search
            </Button>

            <Button
              onClick={() => ClearSearchParams()}
              type="button"
              variant="bad"
            >
              Clear
            </Button>
          </div>
        </form>
      </div>
    );
  };

  const Summary = () => {
    const loadCount = tableData?.length;
    const totalRevenue =
      tableData?.reduce(
        (sum, row) => sum + Number(row.revenue.replace(/[^\d.-]/g, '')),
        0,
      ) ?? 0;
    const totalEstFuel =
      tableData?.reduce(
        (sum, row) => sum + Number(row.estFuel.replace(/[^\d.-]/g, '')),
        0,
      ) ?? 0;
    const totalPayroll =
      tableData?.reduce(
        (sum, row) => sum + Number(row.payroll.replace(/[^\d.-]/g, '')),
        0,
      ) ?? 0;
    const totalPayable =
      tableData?.reduce(
        (sum, row) => sum + Number(row.payable.replace(/[^\d.-]/g, '')),
        0,
      ) ?? 0;
    const totalMargin =
      tableData?.reduce(
        (sum, row) => sum + Number(row.margin.replace(/[^\d.-]/g, '')),
        0,
      ) ?? 0;
    const marginPercentage =
      totalRevenue === 0 ? 0 : ((totalMargin / totalRevenue) * 100).toFixed(2);

    return (
      <div className={classesFinancialReport.summaryRow}>
        <div>
          <span className={classesFinancialReport.summaryLabel}>
            Load Count:
          </span>
          {loadCount}
        </div>
        <div>
          <span className={classesFinancialReport.summaryLabel}>
            Total Revenue:
          </span>
          {totalRevenue.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </div>
        <div>
          <span className={classesFinancialReport.summaryLabel}>
            Total Est. Fuel:
          </span>
          {totalEstFuel.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </div>
        <div>
          <span className={classesFinancialReport.summaryLabel}>
            Total Payroll:
          </span>
          {totalPayroll.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </div>
        <div>
          <span className={classesFinancialReport.summaryLabel}>
            Total Payable:
          </span>
          {totalPayable.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </div>
        <div>
          <span className={classesFinancialReport.summaryLabel}>
            Total Margin:
          </span>
          {totalMargin.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </div>
        <div>
          <span className={classesFinancialReport.summaryLabel}>Margin:</span>{' '}
          {marginPercentage}%
        </div>
      </div>
    );
  };

  return (
    <>
      <Header>
        <button
          onClick={() => {
            DownloadReportCSV(tableData);
          }}
          className={classesFinancialReport.downloadButton}
        >
          <MdDownload />
          <span>Download</span>
        </button>
      </Header>
      <SearchForm />
      <Summary />
      {isLoading ? (
        <h3>Generating Report...</h3>
      ) : (
        <BBITable data={tableData} columns={columnConfig} />
      )}
    </>
  );
}

export default LoadFinancials;
